import { Form } from "react-bootstrap";
import { useState } from "react";
import Axios from "axios";

function App() {

  const [details, setDetails] = useState({ name: "", password: "" });
  const [status, setStatus] = useState("");
  const [errorStatus, errorSetStatus] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let name = details.name;
      let password = details.password;

      console.log(name, password)

      Axios.post("https://etrade.123spider.com/api/auth/login4D", {
        name,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            errorSetStatus("");
            setValidated(false);
            let token = res.data.access_token;
            let uid = res.data.id;

            sessionStorage.setItem('token', token);
            sessionStorage.setItem('uid', uid);

            window.location.href = "/dashboard";
            setStatus("Login Successfully");
          } else if (res.status === 401) {
            errorSetStatus("Username and Password does not match");
          } else {
            setStatus("");
          }



        })
        .catch((err) => {
          console.log(err.message);
          errorSetStatus("Username and Password does not match");
        });

    }
    setValidated(true);
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <h1>Account Login</h1>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                required
                onChange={e => setDetails({ ...details, name: e.target.value })}
                value={details.name}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid username.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                required
                placeholder="Password"
                onChange={e => setDetails({ ...details, password: e.target.value })}
                value={details.password}

              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid password.
              </Form.Control.Feedback>
            </Form.Group>

            <button type="submit" className="btn btn-primary input-block-level form-control">Login</button>

          </Form>
          <div>
            {status === "" ? <br className="mt-1"></br> :
              <>
                <br className="mt-2"></br>
                <h6 className="mt-1 text-success">{status}</h6>
              </>
            }
            {errorStatus === "" ? <br className="mt-1"></br> :
              <>
                <br className="mt-2"></br>
                <h6 className="mt-1 text-danger">{errorStatus}</h6>
              </>
            }
          </div>

        </div>


      </div>

    </>
  );
}

export default App;


