import React from "react";
import "../App.css";

import { useState, useEffect } from "react";
import Axios from "axios";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Table, Card, Form, Col, Row } from "react-bootstrap";
import DeleteConfirmation from "../Components/DeleteConfirmation";
import moment from "moment";

 
const initialState = {
  dayname: "",
};

function Offdays(props) {

  const [{  dayname }, setState] = useState(initialState);

  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));


  };


  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState("");

  // const [details, setDetails] = useState({ name: "", password: "" });

  const handleSubmit = (e) => {



    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {

      Axios.post("https://etrade.123spider.com/api/admin/fourOpenDay", {

        dayname
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            Axios.get(
              `https://etrade.123spider.com/api/admin/fourOpenDay`,

              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              }
            )
              .then((res) => {
                setStatus("Success!");
                setValidated(false);
                setUpdate(false);
                clearState();
                setTimeout(() => {
                  setStatus("");
                }, 5000);

                //setDatas(res.data.data.lucky);
                //console.log(res.data.data)
                setDatas(res.data.data);
              })

              .catch((err) => {
                console.log(err.message);
              });
          }


        })
        .catch((err) => {
          console.log(err.message)
        });
    }

    setValidated(true);



  }

  function formatDate(date) {
    let oncount = moment(date).format("DD-MMM-YYYY");
    return oncount;
  }

  const project = (datas) => {
    switch (datas) {

      case "11": return "11 AM";
      case "1": return "1 PM";
      case "3": return "3 PM";
      case "5": return "5 PM";
      case "7": return "7 PM";
      case "9": return "9 PM";


      default: return datas
    }
  }





  const [datas, setDatas] = useState([]);
  let uid = sessionStorage.getItem("uid");

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (id) => {
    console.log(id);
    setStatus("");
    setDeleteMessage(`Are you sure you want to delete?`);
    setUpdateId(id);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(updateId);
    setDisplayConfirmationModal(false);
    Axios.delete(
      `https://etrade.123spider.com/api/admin/fourOpenDay/` + updateId,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.status);
        //setDatas(res.data.data);
        if (res.status === 200) {
          Axios.get(
            `https://etrade.123spider.com/api/admin/fourOpenDay`,

            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setStatus("Success")
              setTimeout(() => {
                setStatus("");
              }, 5000);
              console.log(res.status);
              setDatas(res.data.data);
            })

            .catch((err) => {
              console.log(err.message);
            });
        }
      })

      .catch((err) => {
        console.log(err.message);
      });
  };



  useEffect(() => {


    Axios.get(
      `https://etrade.123spider.com/api/admin/fourOpenDay`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.status);
        setDatas(res.data.data);
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function handleEditClicks(id, names) {
    //e.preventDefault();    
    setStatus("");
    setUpdate(true);
    console.log(id);
    console.log(names);
    setUpdateId(id);
    setState((prevState) => ({ ...prevState, name: names }));
  }

  function handleEditCancel() {
    setStatus("");
    setUpdate(false);
    clearState();
  }

  const updateData = (e) => {
    console.log(updateId);
    e.preventDefault();
    if (updateId != null) {
      Axios.put(
        `https://etrade.123spider.com/api/admin/updateUser/` + updateId, {
        dayname
      },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          Axios.get(
            `https://etrade.123spider.com/api/admin/allUser`,

            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              setStatus("Success!");
              setValidated(false);
              setUpdate(false);
              clearState();
              setTimeout(() => {
                setStatus("");
              }, 5000);

              //setDatas(res.data.data.lucky);
              //console.log(res.data.data)
              setDatas(res.data.data);
            })

            .catch((err) => {
              console.log(err.message);
            });
        }

      }).catch((err) => {
        console.log(err.message)
      });
    }
  }


  return (
    <>
      {/* <Navbar /> */}

      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-3">
            <Card>
              <Card.Body>
                <h6 className="mb-4">Create Open Day</h6>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                  {/* <div className="mt-1"></div> */}
                  {/* <Form.Select
                    aria-label="Default select example"
                    onChange={onChange}
                    required
                    name="timename"
                    value={timename}
                  >
                    <option>Select Time</option>
                    <option value="11">11 AM</option>
                    <option value="1">1 PM</option>
                    <option value="3">3 PM</option>
                    <option value="5">5 PM</option>
                    <option value="7">7 PM</option>
                    <option value="9">9 PM</option>
                  </Form.Select> */}

                  <div className="mt-1"></div>
                  <div className="row">
                    <div>
                      <Form.Group>
                        <Form.Label>Select Date</Form.Label>
                        <Form.Control type="date" required value={dayname} onChange={onChange} name="dayname" placeholder="Date" />
                      </Form.Group>
                    </div>
                  </div>
                  <br className="mt-1"></br>
                  <button type="submit" className="btn btn-primary input-block-level form-control">Add</button>
                  <br className="mt-3"></br>

                </Form>
                {status === "" ? <br className="mt-1"></br> :
                  <>
                    <br className="mt-1"></br>
                    <h6 className="mt-1 text-success">{status}</h6>
                  </>
                }

              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-9">
            <Table responsive="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>No</th>
                  {/* <th>Time</th> */}
                  <th>Date</th>
                  <th>Delete</th>

                </tr>
              </thead>
              <tbody>
                {datas.map((data, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    {/* <td>{project(data.timename)}</td> */}
                    {/* {
                      data.timename === "8" || data.timename === "10" ? <td>{data.timename} AM</td> : <td>{data.timename} PM</td>
                    } */}
                    <td>{data.dayname}</td>
                    <th>
                      <FiTrash2 className="icon"
                        onClick={() => showDeleteModal(data.id)}
                        style={{
                          color: 'red',
                          top: '20px',
                          right: '20px',
                        }}
                        size="25px" />
                    </th>

                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} message={deleteMessage} />
    </>
  );
}

export default Offdays;
